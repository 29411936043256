
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Yup from "@/bundle/validations/YupExpended";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const key = ref<string>('');
    const route = useRoute();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      // email: Yup.string().email().required().label("L'adresse e-mail"),
      newPassword : Yup.string().noWhiteSpace().min(8).required().label("Le mot de passe"),
      confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Le mot de passe et la confirmation ne correspondent pas')
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {

      var data = {newPassword: values.newPassword, key: key.value}
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.CHANGE_PASSWORD, data)
          .then(() => {
            Swal.fire({
              title: 'Réinitialisation du mot de passe',
              text: "Un e-mail contenant les instructions de réinitialisation du mot de passe vous a été envoyé.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, d'accord!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "dashboard" });
            });
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Essayez encore!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    onMounted(() => {
      key.value = route.params.key as string;
    });

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
